import './Contact.css';
import { Helmet } from 'react-helmet';

import Channel from './channel/Channel';
import Questions from './questions/Questions';
import usePageTracking from '../../../hooks/usePageTracking';


const Contact = () => {

    usePageTracking();

    return (
        <section className="atendimento">
            <Helmet>
                <title>Contato | Plano Santa Saúde</title>
                <meta name="description" content="Entre em contato com o Plano Santa Saúde. Estamos aqui para responder suas dúvidas voltadas para contratação e oferecer suporte para todas as suas necessidades de saúde." />
            </Helmet>
            <div className="atendimento__title">
                <h2>
                    CANAIS DE ATENDIMENTO DIRECIONADOS ÀS VENDAS
                </h2>
            </div>

            <Channel />
            <Questions />

        </section>
    )
}

export default Contact;