import './AboutInfo.css';
import unidadesantasaudemobile from '../../../../assets/img-main/unidadesantasaudemobile.jpeg'

const AboutInfo = () => {
    return (
        <div className='about__info'>
            <div className='about__info__text'>
                <h3 className='about__subtitle'>
                    PLANO SANTA SAÚDE, CONHEÇA A NOSSA HISTÓRIA
                </h3>
                <h2 className='about__title'>
                    CUIDANDO DE VIDAS HÁ MAIS DE 30 ANOS
                </h2>
                <p className='about__paragraph'>
                    O Plano Santa Saúde foi inaugurado em setembro de 1992 em um prédio no Centro Histórico de Santos.
                </p>
                <p className='about__paragraph'>
                    Sendo um dos pioneiros planos de saúde do Brasil, obtivemos uma excelente receptividade e reconhecimento de nossos beneficiários.
                </p>
                <p className='about__paragraph'>
                    Hoje, somos um dos maiores planos de saúde em Santos e região, com mais de 130 mil beneficiários, com ampla rede médica e unidades espalhadas por toda Baixada Santista.
                </p>
            </div>
            <div>
                <img
                src={unidadesantasaudemobile}
                alt="Unidade Plano Santa Saúde"
                />
            </div>
        </div>
    );
}

export default AboutInfo;
