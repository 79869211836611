import './Mission.css';

import eyeicon from "../../../../assets/img-main/eye.png"
import targeticon from "../../../../assets/img-main/target.png"
import valueicon from "../../../../assets/img-main/value.png"

const Mission = () => {
    return (
        <div className="valores__info">
            <ul className="valores__info-list">

                <li className="valores__info-item">
                    <div className="info-item-titulo">
                        <img
                            src={targeticon}
                            alt="ícone de alvo/foco/missão"
                        />
                        <h4>
                            Nossa Missão
                        </h4>
                    </div>
                    <p>
                        Oferecer assistência médica de qualidade com a promoção integral da saúde e responsabilidade social. Tudo para garantir a sustentabilidade da organização, satisfação dos clientes, prestadores de serviços e colaboradores.
                    </p>
                </li>

                <li className="valores__info-item">
                    <div className="info-item-titulo">
                        <img
                            src={valueicon}
                            alt="ícone de valores da empresa"
                        />
                        <h4>
                            Nossos Valores
                        </h4>
                    </div>
                    <p>
                        Foco no cliente, humanização, comprometimento, ética, competência, dedicação, respeito, eficiência e transparência.
                    </p>
                </li>

                <li className="valores__info-item">
                    <div className="info-item-titulo">
                        <img
                            src={eyeicon}
                            alt="Ícone de visão da empresa"
                        />
                        <h4>
                            Nossa Visão
                        </h4>
                    </div>
                    <p>
                        Ser referência como melhor operadora de planos em sua área de ação geográfica, e como modelo de gestão integrada com o maior Hospital da Baixada Santista.
                    </p>
                </li>

            </ul>

        </div>
    )
}

export default Mission;