import './Planos.css'
import { Helmet } from 'react-helmet';

import usePageTracking from '../../../hooks/usePageTracking.js';
import CardPlanos from './CardPlanos/CardPlanos.js';

const Planos = () => {

    usePageTracking();

    return (
        <section className="page__plans-section">
            <Helmet>
                <title>Planos de Saúde | Plano Santa Saúde</title>
                <meta name="description"
                    content="Explore os diversos planos de saúde oferecidos pela GoCare Litoral. Planos individuais, familiares e empresariais com cobertura completa e atendimento humanizado na Baixada Santista." />
            </Helmet>
            <div className="page__plans-container">
                <div className="page__plans-text">
                    <div className="page__plans-title">
                        <h2><strong>Valores e Coberturas</strong> de nossos <strong>Planos!</strong></h2>
                    </div>
                    <div className="div-separate"></div>
                </div>

                <CardPlanos />

            </div >

        </section >

    )
}

export default Planos;