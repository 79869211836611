import { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Header.css";

import logo from "../../assets/img-header/logo.png";
import menuicon from "../../assets/img-header/menuicon.png";

const Header = () => {
    const location = useLocation();

    const dropDownRef = useRef(null);
    const [isActive, setIsActive] = useState(false);

    const onClick = () => {
        setIsActive(!isActive);
    };

    const handleClickOutside = (event) => {
        if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
            setIsActive(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <header className="header">
            <div className="logo__container">
                <Link to="/" className="navigation__logo__link">
                    <img src={logo} className="header__logo" alt="Logo Plano Santa Saúde" />
                </Link>
                <p><span>SEMPRE PERTO</span><br /> CUIDANDO DE VOCÊ!</p>
            </div>
            <div className="navigation">
                <button onClick={onClick} className="menu-button">
                    <span>Menu</span>
                    <img src={menuicon} alt="Menu" />
                </button>
                <nav className={`menu__nav ${isActive ? 'open' : ''}`} ref={dropDownRef}>
                    <ul className="menu__ul">
                        <li className="menu__li">
                            <Link to="/" className={`header__link ${location.pathname === '/' ? 'active' : ''}`}>
                                <span className="hover">Início</span>
                            </Link>
                        </li>
                        <li className="menu__li">
                            <Link to="/sobre" className={`header__link ${location.pathname === '/sobre' ? 'active' : ''}`}>
                                <span className="hover">Sobre Nós</span>
                            </Link>
                        </li>
                        <li className="menu__li">
                            <Link to="/planos" className={`header__link ${location.pathname === '/planos' ? 'active' : ''}`}>
                                <span className="hover">Planos</span>
                            </Link>
                        </li>
                        <li className="menu__li">
                            <Link to="/cliente" className={`header__link ${location.pathname === '/cliente' ? 'active' : ''}`}>
                                <span className="hover">Seja Cliente</span>
                            </Link>
                        </li>
                        <li className="menu__li">
                            <Link to="/contato" className={`header__link ${location.pathname === '/contato' ? 'active' : ''}`}>
                                <span className="hover">Dúvidas</span>
                            </Link>
                        </li>
                    </ul>
                </nav>
            </div>
        </header>
    );
};

export default Header;
