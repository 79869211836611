
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './Benefits.css';

import benefit_cobertura from '../../../../assets/img-main/benefits/benefits-cobertura.png';
import benefit_atendimento from '../../../../assets/img-main/benefits/benefits-atendimento.png';
import benefit_odonto from '../../../../assets/img-main/benefits/benefits-odonto.png';
import benefit_facilidade from '../../../../assets/img-main/benefits/benefits-facilidade.png';
import benefit_24h from '../../../../assets/img-main/benefits/benefits-24h.png';
import benefit_preco from '../../../../assets/img-main/benefits/benefits-preco.png';
import benefit_desconto from '../../../../assets/img-main/benefits/benefits-desconto.png';
import benefit_burocracia from '../../../../assets/img-main/benefits/benefits-burocracia.png';



const BenefitsCarousel = () => {
    const benefits = [
        benefit_cobertura,
        benefit_atendimento,
        benefit_odonto,
        benefit_facilidade,
        benefit_24h,
        benefit_preco,
        benefit_desconto,
        benefit_burocracia
    ];

    return (
        <section className="benefits">

            <div className="benefits__title">
                <h1>POR QUE CONTRATAR O<br /><span>PLANO SANTA SAÚDE?</span></h1>
                <p className='benefits__conclusao'>
                    Contratar um plano de saúde é uma decisão importante que afeta diretamente a qualidade de vida e a segurança de sua família. O Plano Santa Saúde se destaca no mercado por oferecer diversas vantagens que tornam essa escolha ainda mais atrativa. A seguir, apresentamos alguns dos principais motivos para optar por este plano.
                </p>
            </div>

            <Swiper
                modules={[Autoplay, Navigation]}
                spaceBetween={0}
                slidesPerView={2}
                loop={true}
                autoplay={{ delay: 2000 }}
                breakpoints={{
                    360: {
                        slidesPerView: 2,
                    },
                    768: {
                        slidesPerView: 3,
                    },
                    1025: {
                        slidesPerView: 4,
                    },
                    1440: {
                        slidesPerView: 5,
                    }
                }}
                navigation={true}
                className="custom-swiper"
            >
                {benefits.map((benefit, index) => (
                    <SwiperSlide key={index}>
                        <img
                            src={benefit}
                            alt={`Slide Benefícios ${index + 1}`}
                        />
                    </SwiperSlide>
                ))}
            </Swiper>

            <div className="benefits___container">
                <div className="benefits__content preco">
                    <div></div>
                    <div className="benefits-text">
                        <h3>PREÇO BAIXO E ACESSÍVEL</h3>
                        <p>
                            Uma das maiores preocupações ao contratar um plano de saúde é o custo. O Plano Santa Saúde se destaca por oferecer preços imbatíveis, que cabem no seu bolso, sem comprometer a qualidade do atendimento. A acessibilidade dos valores permite que mais pessoas possam se beneficiar de um serviço de saúde de qualidade, garantindo tranquilidade em momentos de necessidade.
                        </p>
                    </div>
                </div>

                <div className="benefits__content online">
                    <div className="benefits-text">
                        <h3>CONTRATAÇÃO ONLINE, SEM BUROCRACIA</h3>
                        <p>
                            A praticidade na contratação é um diferencial importante. Com o Plano Santa Saúde, você pode realizar todo o processo de adesão online, de maneira rápida e sem complicações. Esqueça as filas e a burocracia; com apenas alguns cliques, você pode garantir a proteção que sua família merece, tudo de forma ágil e segura.
                        </p>
                    </div>
                    <div></div>
                </div>

                <div className="benefits__content descontos">
                    <div></div>
                    <div className="benefits-text">
                        <h3>DESCONTOS EXCLUSIVOS</h3>
                        <p>
                            Ao se tornar um beneficiário do Plano Santa Saúde, você tem acesso a uma série de descontos exclusivos em diversos serviços e estabelecimentos. Esses benefícios adicionais representam uma economia significativa no seu orçamento, além de ampliar as opções de cuidado e bem-estar.
                        </p>
                    </div>
                </div>

                <div className="benefits__content odonto">
                    <div className="benefits-text">
                        <h3>PLANO ODONTOLÓGICO INCLUSO</h3>
                        <p>
                            Cuidar da saúde bucal é essencial para o bem-estar geral. O Plano Santa Saúde inclui um plano odontológico, garantindo que você e sua família possam realizar consultas, procedimentos e tratamentos odontológicos sem custos adicionais. Essa inclusão é um grande diferencial, pois muitas vezes os planos odontológicos são contratados separadamente, gerando mais despesas.
                        </p>
                    </div>
                    <div></div>
                </div>

                <div className="benefits__content farmacia">
                    <div></div>
                    <div className="benefits-text">
                        <h3>DESCONTO EM FARMÁCIA</h3>
                        <p>
                            Outro benefício relevante é o desconto em farmácias conveniadas. Medicamentos e produtos de saúde costumam ser itens de consumo constante, e ter um desconto nesses produtos pode aliviar bastante o orçamento familiar. O Plano Santa Saúde proporciona essa vantagem, tornando o cuidado contínuo mais acessível.
                        </p>
                    </div>
                </div>

            </div>
            <p className='benefits__conclusao'>
                Contratar o Plano Santa Saúde é uma decisão inteligente para quem busca um plano de saúde completo, acessível e prático. Com preços imbatíveis, facilidade na contratação, benefícios exclusivos, plano odontológico incluso e descontos em farmácias, oferecemos uma excelente relação custo-benefício. Garanta a segurança e o bem-estar de sua família com o Plano Santa Saúde e desfrute de todos esses benefícios sem comprometer o seu orçamento.
            </p>
            <h3><span>SEMPRE PERTO</span>, CUIDANDO DE VOCÊ!</h3>
        </section>
    );
};

export default BenefitsCarousel;
