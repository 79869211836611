import "./Cobertura.css";
import hospitalicon from "../../../../assets/img-main/hospital.png"

const Cobertura = () => {
    return (
        <div className="cobertura__container">

            <div className="cobertura__titulo">
                <h2>
                    HOSPITAIS CREDENCIADOS
                </h2>
            </div>

            <div className="rede__container">
                <div className="redes">
                    <div className="redes__info">
                        <img
                            src={hospitalicon}
                            alt="Ícone de um hospital"
                        />
                        <p>
                            Santos
                        </p>
                    </div>

                    <div className="redes__info-texto">
                        <p>
                            Pronto Atendimento Adulto 24 horas
                        </p>
                        <p>
                            Av. Conselheiro Nébias, 748<br/>
                            Boqueirão
                        </p>
                    </div>
                </div>


                <div className="redes">
                    <div className="redes__info">
                        <img
                            src={hospitalicon}
                            alt="Ícone de um hospital"
                        />
                        <p className="cobertura__redes-cidade">
                            São Vicente
                        </p>
                    </div>

                    <div className="redes__info-texto">
                        <p>
                            Hospital São José
                        </p>
                        <p>
                            Rua Frei Gaspar, 790<br/> 
                            Centro
                        </p>
                    </div>
                </div>


                <div className="redes">
                    <div className="redes__info">
                        <img
                            src={hospitalicon}
                            alt="Ícone de um hospital"
                        />
                        <p className="cobertura__redes-cidade">
                            Praia Grande
                        </p>
                    </div>

                    <div className="redes__info-texto">
                        <p>
                            Santa Saúde
                        </p>
                        <p>
                            Av. Presidente Kennedy, 1947<br/> 
                            Vila Guilhermina
                        </p>
                    </div>
                </div>


                <div className="redes">
                    <div className="redes__info">
                        <img
                            src={hospitalicon}
                            alt="Ícone de um hospital"
                        />
                        <p>
                            Guarujá
                        </p>
                    </div>

                    <div className="redes__info-texto">
                        <p>
                            Santa Saúde
                        </p>
                        <p>
                            Av. Ademar de Barros, 2269<br/> 
                            Vila Santa Rosa
                        </p>
                    </div>
                </div>


                <div className="redes">
                    <div className="redes__info">
                        <img
                            src={hospitalicon}
                            alt="Ícone de um hospital"
                        />
                        <p>
                            Cubatão
                        </p>
                    </div>

                    <div className="redes__info-texto">
                        <p>
                            Hospital Ana Costa
                        </p>
                        <p>
                            Av. Nove de Abril, 1601<br/> 
                            Vila Nova
                        </p>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Cobertura;