import React from 'react';
import "./Main.css";
import { Routes, Route, Navigate } from 'react-router-dom';
import Home from './home/Home';
import About from './about/About';
import Cliente from './client/Client';
import Contato from './contact/Contact';
import Wpp from './wpp/Wpp';
import Planos from './Planos/Planos';
import FormConfirmation from './client/formconfimation/FormConfirmation';
import NotFound from './NotFound/NotFound';

const Main = () => {
  return (
    <main className='main'>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/sobre" element={<About />} />
        <Route path="/cliente" element={<Cliente />} />
        <Route path="/contato" element={<Contato />} />
        <Route path="/planos" element={<Planos />} />
        <Route path="/confirmacao" element={<FormConfirmation />} />
        <Route path="/about" element={<Navigate to="/sobre" />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Wpp />
    </main>
  );
};

export default Main;
